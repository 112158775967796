import React from "react"
import PropTypes from "prop-types"
import ButtonCustom from "../ButtonCustom"
import Icon from "../Icon"
import { DONATION_TYPES } from "../../constants/donation-types"
import { formatMoney } from "../../utils/format"

import styles from "./styles.module.scss"

const CardDonation = ({ data, handleClick, isClosed = false, isSelected }) => {
  const {
    name,
    is_available: isAvailable,
    description,
    need_type: type,
    value,
  } = data

  const handleClickAndLoad = () => {
    if (!isAvailable) return
    if (isClosed) return
    handleClick(data)
  }

  const buttonText = isSelected
    ? "Selected"
    : isAvailable && !isClosed
    ? "Donate now"
    : "Covered"
  const buttonIcon = isSelected
    ? "heart"
    : isAvailable && !isClosed
    ? ""
    : "check"

  return (
    <article // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      className={`${styles.card} ${!isAvailable && styles.isAvailable}`}
      onClick={handleClickAndLoad}
    >
      <div className={styles.cardTitleRowSvg}>
        {
          // The div tag to create the new image icon
          <img
            alt={type}
            src={
              type === "OTHER"
                ? "/needsIcons/Misc.svg"
                : "/needsIcons/" +
                  type.substring(0, 1) +
                  type.substring(1).toLowerCase() +
                  ".svg"
            }
          />
        }
      </div>
      <p className="small-header">{`${formatMoney(value)} for ${name}`}</p>
      <p className="small-body-copy">{description}</p>
      <ButtonCustom
        disabled={!isAvailable || isClosed}
        className={styles.donationButton}
        hierarchy={isSelected ? "secondary" : "newPrimary"}
        icon={buttonIcon}
        iconClassName={isSelected && styles.selectedButtonIcon}
        size="medium"
        role="button"
      >
        {buttonText}
      </ButtonCustom>
    </article>
  )
}

CardDonation.defaultProps = {
  data: {
    type: DONATION_TYPES.OTHER,
  },
}

CardDonation.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    isAvailable: PropTypes.bool.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.number,
  }),
  handleClick: PropTypes.func.isRequired,
}

export default CardDonation
