import { gql } from "@apollo/client"

export const GET_CHECKOUT_SESSION_BY_SESSION_ID = gql`
  query findCheckoutSessionBySessionId($id: String!) {
    checkoutSession: checkouts(where: { session_id: { _eq: $id } }) {
      checkout_id
      needs {
        need_id
        name
        value
        description
        need_type
        is_available
        family {
          family_id
          display_name
        }
      }
    }
  }
`
